import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

const YesNoButtons = ({ onClick }) => {
  const { t } = useTranslation();
  const { pageName } = useParams();
  const methods = useFormContext();

  const handleClick = (value) => () => {
    methods.setValue(pageName, value);

    onClick && onClick();
  };

  return (
    <Stack useFlexGap sx={{ gap: 9 / 8 }} direction="row">
      <Button
        fullWidth
        disableElevation
        variant="outlined"
        size="large"
        onClick={handleClick('yes')}
      >
        {t('common.yes')}
      </Button>

      <Button
        fullWidth
        disableElevation
        variant="contained"
        size="large"
        onClick={handleClick('no')}
      >
        {t('common.no')}
      </Button>
    </Stack>
  );
};

export default YesNoButtons;

import SelectButton from 'components/SelectButton';
import MultiCheckbox from 'components/MultiCheckbox';
import YesNoButtons from '../layouts/components/YesNoButtons';
import {
  WEB_SETTING_USER_GOAL_STARTED,
  WEB_SETTING_USER_GOAL_STARTED_2,
  WEB_SHARING_ABOUT_USER_STARTED_11,
  WEB_SHARING_ABOUT_USER_STARTED_12,
  WEB_SHARING_ABOUT_USER_STARTED_13,
  WEB_SHARING_ABOUT_USER_STARTED_15,
  WEB_SHARING_ABOUT_USER_STARTED_16,
  WEB_SHARING_ABOUT_USER_STARTED_2,
  WEB_SHARING_ABOUT_USER_STARTED_3,
  WEB_SHARING_ABOUT_USER_STARTED_4,
  WEB_SHARING_ABOUT_USER_STARTED_5,
  WEB_SHARING_ABOUT_USER_STARTED_6,
  WEB_SHARING_ABOUT_USER_STARTED_7,
  WEB_SHARING_ABOUT_USER_STARTED_8,
  WEB_SHARING_ABOUT_USER_STARTED_9,
  WEB_TELLING_ABOUT_USER_PREFERENCES_STARTED,
  WEB_TELLING_ABOUT_USER_PREFERENCES_STARTED_2,
  WEB_TELLING_ABOUT_USER_PREFERENCES_STARTED_3,
  WEB_TELLING_ABOUT_USER_PREFERENCES_STARTED_4,
} from './AmplitudeTrackEvents';

export const DEBUG = parseInt(process.env.REACT_APP_DEBUG) === 1;

// console.log('DEBUG', DEBUG);

// export const AMPLITUDE_API_KEY = DEBUG
//   ? process.env.REACT_APP_AMPLITUDE_API_DEV_KEY
//   : process.env.REACT_APP_AMPLITUDE_API_KEY;

export const AMPLITUDE_API_KEY = process.env.REACT_APP_AMPLITUDE_API_KEY;

// export const REACT_APP_SENTRY_TRACK_NEW_RELEASE = process.env.REACT_APP_SENTRY_TRACK_NEW_RELEASE;
// export const REACT_APP_SENTRY_SECRET_TOKEN = process.env.REACT_APP_SENTRY_SECRET_TOKEN;
// export const REACT_APP_SENTRY_PROJECT_ID = process.env.REACT_APP_SENTRY_PROJECT_ID;
// export const REACT_APP_SENTRY_PUBLIC_KEY = process.env.REACT_APP_SENTRY_PUBLIC_KEY;

// export const ACCESS_TOKEN       = 'access_token';

export const DOWNLOAD_ONE_LINK = '//hubapp.onelink.me/ZKxz?pid=FB_W2A_iOS15';
// export const DOWNLOAD_ONE_LINK = '//hubapp.onelink.me/ZKxz?pid=FB_W2A_iOS15&af_sub4=3643'

export const CONTENT_TYPE_JSON = 'application/json;charset=utf-8';

/**
 * @description Time out in seconds
 */
export const GET_REQUEST_TIMEOUT = 10000;

export const HTTP_METHOD_GET = 'GET';
export const HTTP_METHOD_POST = 'POST';

export const OUT_FORMAT_TEXT = 'text';
export const OUT_FORMAT_BLOB = 'blob';
export const OUT_FORMAT_JSON = 'json';

// export const PAYMENT_TYPE_STRIPE = 'stripe';
// export const PAYMENT_TYPE_PAYPAL = 'paypal';

export const languageList = ['en', 'de', 'es', 'pt', 'ru', 'fr', 'it'];

export const PAGES_MAP = {
  '/': 'Page2',
  Page1: 'Page2',
  Page2: 'Page3',
  Page3: 'Page4',
  Page4: 'all-in-one-app',
  'all-in-one-app': 'Page6',
  Page6: 'Page7',
  Page7: 'Page8',
  Page8: 'Page9',
  Page9: 'Page10',
  Page10: 'Page11',
  Page11: 'Page12',
  Page12: 'Page13',
  Page13: 'Page14',
  Page14: 'Page15',
  Page15: 'Page16',
  Page16: 'Page17',
  Page17: 'Page18',
  Page18: 'Page19',
  Page19: 'Page20',
  Page20: 'Page21',
  Page21: 'Page22',
  Page22: 'Page23',
  Page23: 'Page24',
  Page24: 'Page25',
  Page25: 'Page26',
  Page26: 'Page27',
  Page27: 'Page28',
  Page28: 'Page29',
  Page29: 'Page30',
  Page30: 'email',
  // 'email': 'analyzing',
  email: 'Page31',
  Page31: 'analyzing',
  analyzing: 'paywall',
  paywall: 'subscriptions',
};

export const FLOW_LIST = ['default'];

export const LIST_PAGES = [
  ...Object.keys(PAGES_MAP),
  ...Object.values(PAGES_MAP),
].map((page) => `/${page}`);

export const PAGE_QUESTIONS = {
  Page7: {
    btnText: 'dynamicPage.buttons.lets_go',
  },
  Page8: {
    eventName: WEB_SHARING_ABOUT_USER_STARTED_2,
    sectionName: 'dynamicPage.sectionName.goal',
    label: 'dynamicPage.labels.primary_goal',
    QuestionButtonType: SelectButton,
    activeStep: 1,
    btnText: 'dynamicPage.buttons.next',
    questions: [
      {
        value: 'grow_followers',
        label: 'dynamicPage.questions.grow_followers',
      },
      {
        value: 'boost_engagement',
        label: 'dynamicPage.questions.boost_engagement',
      },
      {
        value: 'gain_more_sales_for_my_business',
        label: 'dynamicPage.questions.gain_more_sales_for_my_business',
      },
      {
        value: 'get_higher_engagement_rate',
        label: 'dynamicPage.questions.get_higher_engagement_rate',
      },
      {
        value: 'automate_content_creation',
        label: 'dynamicPage.questions.automate_content_creation',
      },
    ],
  },
  Page9: {
    eventName: WEB_SHARING_ABOUT_USER_STARTED_3,
    sectionName: 'dynamicPage.sectionName.describe_yourself',
    label: 'dynamicPage.labels.going_to_use',
    QuestionButtonType: SelectButton,
    activeStep: 2,
    btnText: 'dynamicPage.buttons.next',
    questions: [
      {
        value: 'business_owner',
        label: 'dynamicPage.questions.business_owner',
      },
      {
        value: 'marketing_professional',
        label: 'dynamicPage.questions.marketing_professional',
      },
      {
        value: 'influencer_or_blogger',
        label: 'dynamicPage.questions.influencer_or_blogger',
      },
      {
        value: 'personal_use',
        label: 'dynamicPage.questions.personal_use',
      },
      {
        value: 'other',
        label: 'common.other',
      },
    ],
  },
  Page10: {
    eventName: WEB_SHARING_ABOUT_USER_STARTED_4,
    sectionName: 'dynamicPage.sectionName.describe_yourself',
    label: 'dynamicPage.labels.content_about',
    QuestionButtonType: MultiCheckbox,
    activeStep: 2,
    btnText: 'dynamicPage.buttons.next',
    questions: [
      {
        value: 'e-commerce',
        label: 'dynamicPage.questions.e-commerce',
      },
      {
        value: 'beauty',
        label: 'dynamicPage.questions.beauty',
      },
      {
        value: 'fashion',
        label: 'dynamicPage.questions.fashion',
      },
      {
        value: 'Health & Fitness',
        label: 'dynamicPage.questions.health_&_fitness',
      },
      {
        value: 'Photography',
        label: 'dynamicPage.questions.photography',
      },
      {
        value: 'Lifestyle',
        label: 'dynamicPage.questions.lifestyle',
      },
      {
        value: 'Art & Design',
        label: 'dynamicPage.questions.art_&_design',
      },
      {
        value: 'Food',
        label: 'dynamicPage.questions.food',
      },
      {
        value: 'Education',
        label: 'dynamicPage.questions.education',
      },
      {
        value: 'Music',
        label: 'dynamicPage.questions.music',
      },
      {
        value: 'Travel',
        label: 'dynamicPage.questions.travel',
      },
    ],
  },
  Page11: {
    eventName: WEB_SHARING_ABOUT_USER_STARTED_5,
    sectionName: 'dynamicPage.sectionName.instagram_activity',
    label: 'dynamicPage.labels.spend_time',
    QuestionButtonType: SelectButton,
    activeStep: 3,
    btnText: 'dynamicPage.buttons.next',
    questions: [
      {
        value: '1_hour_a_week',
        label: 'dynamicPage.questions.1_hour_a_week',
      },
      {
        value: '2_3_hours_a_week',
        label: 'dynamicPage.questions.2_3_hours_a_week',
      },
      {
        value: '4_5_hours_a_week',
        label: 'dynamicPage.questions.4_5_hours_a_week',
      },
      {
        value: '5_10_hours_a_week',
        label: 'dynamicPage.questions.5_10_hours_a_week',
      },
      {
        value: '10_20_hours_a_week',
        label: 'dynamicPage.questions.10_20_hours_a_week',
      },
      {
        value: 'more_then_20_hours_a_week',
        label: 'dynamicPage.questions.more_then_20_hours_a_week',
      },
    ],
  },
  Page12: {
    eventName: WEB_SHARING_ABOUT_USER_STARTED_6,
    sectionName: 'dynamicPage.sectionName.instagram_activity',
    label: 'dynamicPage.labels.followers_you_have',
    activeStep: 3,
    QuestionButtonType: SelectButton,
    questions: [
      {
        value: 'less_then_100',
        label: '< 100',
      },
      {
        value: '101_500',
        label: '101 - 500',
      },
      {
        value: '501_1000',
        label: '501 - 1000',
      },
      {
        value: '1001_10000',
        label: '1001 - 10000',
      },
      {
        value: '10001_50000',
        label: '10 001 - 50 000',
      },
      {
        value: '50001_100000',
        label: '50 001 - 100 000',
      },
    ],
  },
  Page13: {
    eventName: WEB_SHARING_ABOUT_USER_STARTED_7,
    sectionName: 'dynamicPage.sectionName.instagram_activity',
    label: 'dynamicPage.labels.posts_last_week',
    QuestionButtonType: SelectButton,
    activeStep: 3,
    questions: [
      {
        value: '0',
        label: '0',
      },
      {
        value: '1_2',
        label: '1 - 2',
      },
      {
        value: '3_4',
        label: '3 - 4',
      },
      {
        value: '5_6',
        label: '5 - 6',
      },
      {
        value: '7_8',
        label: '7 - 8',
      },
      {
        value: '9_10',
        label: '9 - 10',
      },
    ],
  },
  Page14: {
    eventName: WEB_SHARING_ABOUT_USER_STARTED_8,
    sectionName: 'dynamicPage.sectionName.instagram_activity',
    label: 'dynamicPage.labels.stories_last_week',
    QuestionButtonType: SelectButton,
    activeStep: 3,
    questions: [
      {
        value: '0',
        label: '0',
      },
      {
        value: '1_2',
        label: '1 - 2',
      },
      {
        value: '3_4',
        label: '3 - 4',
      },
      {
        value: '5_6',
        label: '5 - 6',
      },
      {
        value: '7_8',
        label: '7 - 8',
      },
      {
        value: '9_10',
        label: '9 - 10',
      },
    ],
  },
  Page15: {
    eventName: WEB_SHARING_ABOUT_USER_STARTED_9,
    sectionName: 'dynamicPage.sectionName.instagram_activity',
    label: 'dynamicPage.labels.reels_last_week',
    activeStep: 3,
    QuestionButtonType: SelectButton,
    questions: [
      {
        value: '0',
        label: '0',
      },
      {
        value: '1_2',
        label: '1 - 2',
      },
      {
        value: '3_4',
        label: '3 - 4',
      },
      {
        value: '5_6',
        label: '5 - 6',
      },
      {
        value: '7_8',
        label: '7 - 8',
      },
      {
        value: '9_10',
        label: '9 - 10',
      },
    ],
  },
  // Page16: {
  //   sectionName: 'AI Automation',
  //   label: 'Do you have someone to help you with content creation?'
  // },
  Page17: {
    eventName: WEB_SHARING_ABOUT_USER_STARTED_11,
    sectionName: 'dynamicPage.sectionName.ai_automation',
    label: 'dynamicPage.labels.helps_with_content',
    ButtonType: YesNoButtons,
    activeStep: 4,
  },
  Page18: {
    eventName: WEB_SHARING_ABOUT_USER_STARTED_12,
    sectionName: 'dynamicPage.sectionName.ai_automation',
    label: 'dynamicPage.labels.ai_tools',
    ButtonType: YesNoButtons,
    activeStep: 4,
  },
  Page19: {
    eventName: WEB_SHARING_ABOUT_USER_STARTED_13,
    sectionName: 'dynamicPage.sectionName.ai_automation',
    label: 'dynamicPage.labels.type_of_content',
    QuestionButtonType: MultiCheckbox,
    activeStep: 4,
    questions: [
      {
        value: 'video_scripts',
        label: 'dynamicPage.questions.video_scripts',
      },
      {
        value: 'video_hooks',
        label: 'dynamicPage.questions.video_hooks',
      },
      {
        value: 'short_videos',
        label: 'dynamicPage.questions.short_videos',
      },
      {
        value: 'pictures_photos',
        label: 'dynamicPage.questions.pictures_photos',
      },
      {
        value: 'my_avatars',
        label: 'dynamicPage.questions.my_avatars',
      },
      {
        value: 'content_ideas',
        label: 'dynamicPage.questions.content_ideas',
      },
      {
        value: 'Other',
        label: 'common.other',
      },
    ],
  },
  Page21: {
    eventName: WEB_SHARING_ABOUT_USER_STARTED_15,
    sectionName: 'dynamicPage.sectionName.monetization',
    label: 'dynamicPage.labels.monetize',
    ButtonType: YesNoButtons,
    activeStep: 5,
  },
  Page22: {
    eventName: WEB_SHARING_ABOUT_USER_STARTED_16,
    sectionName: 'dynamicPage.sectionName.monetization',
    label: 'dynamicPage.labels.is_monetized',
    QuestionButtonType: SelectButton,
    activeStep: 5,
    questions: [
      {
        value: 'not_yet',
        label: 'dynamicPage.questions.not_yet',
      },
      {
        value: 'made_over_5000',
        label: 'dynamicPage.questions.made_over_5000',
      },
      {
        value: 'made_over_10000',
        label: 'dynamicPage.questions.made_over_10000',
      },
      {
        value: 'this_is_my_main_source_of_income',
        label: 'dynamicPage.questions.this_is_my_main_source_of_income',
      },
      {
        value: 'prefer_not_to_answer',
        label: 'dynamicPage.questions.prefer_not_to_answer',
      },
    ],
    btnText: 'dynamicPage.buttons.next',
  },
  Page23: {
    eventName: WEB_TELLING_ABOUT_USER_PREFERENCES_STARTED,
    sectionName: 'dynamicPage.sectionName.preferences',
    label: 'dynamicPage.labels.choose_a_story',
    activeStep: 6,
    btnText: 'dynamicPage.buttons.this_one',
  },
  Page24: {
    eventName: WEB_TELLING_ABOUT_USER_PREFERENCES_STARTED_2,
    sectionName: 'dynamicPage.sectionName.preferences',
    label: 'dynamicPage.labels.choose_a_story',
    activeStep: 6,
    btnText: 'dynamicPage.buttons.this_one',
  },
  Page25: {
    eventName: WEB_TELLING_ABOUT_USER_PREFERENCES_STARTED_3,
    sectionName: 'dynamicPage.sectionName.preferences',
    label: 'dynamicPage.labels.ai_background_remover',
    activeStep: 6,
    ButtonType: YesNoButtons,
  },
  Page26: {
    eventName: WEB_TELLING_ABOUT_USER_PREFERENCES_STARTED_4,
    sectionName: 'dynamicPage.sectionName.preferences',
    label: 'dynamicPage.labels.outlines',
    activeStep: 6,
    ButtonType: YesNoButtons,
  },
  Page28: {
    eventName: WEB_SETTING_USER_GOAL_STARTED,
    sectionName: 'dynamicPage.sectionName.set_your_goal',
    label: 'dynamicPage.labels.followers_you_want',
    activeStep: 7,
    btnText: 'dynamicPage.buttons.next',
  },
  Page29: {
    eventName: WEB_SETTING_USER_GOAL_STARTED_2,
    sectionName: 'dynamicPage.sectionName.set_your_goal',
    label: 'dynamicPage.labels.monthly_income',
    QuestionButtonType: SelectButton,
    activeStep: 7,
    questions: [
      {
        value: '1000',
        label: '$1000',
      },
      {
        value: '1000_5000',
        label: '$1000 - $5000',
      },
      {
        value: '5000_10000',
        label: '$5000 - $10000',
      },
      {
        value: '10000_15000',
        label: '$10000 - $15000',
      },
      {
        value: 'prefer_not_to_answer',
        label: 'dynamicPage.questions.prefer_not_to_answer',
      },
    ],
    btnText: 'dynamicPage.buttons.next',
  },
};

export const POSITION = {
  LEFT: 'left',
  RIGHT: 'right',
};

export const EMAIL_REGEX =
  /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;

export const LINKS = {
  TERMS: 'https://smmhub.app/terms',
  PRIVACY: 'https://smmhub.app/privacy',
  COOKIES: 'https://smmhub.app/cookies',
  MAILTO: 'mailto:support@smmhub.app',
};

export const LOGIN_BY_EMAIL =
  'https://grids.growthphotographers.com/v1/login_by_email';

export const PAYMENT_SYSTEM = {
  APPLE: 'apple_pay',
  PAYPAL: 'paypal',
  STRIPE: 'stripe',
};

export const EMAIL_DOMAINS = [
  'facebook.com',
  'verizon.net',
  'rediffmail.com',
  'zohomail.com',
  'zoho.com',
  'mail.com',
  'google.com',
  'comcast.com',
  'hotmail.co.uk',
  'yahoo.co.uk',
  'att.net',
  'gmz.com',
  'gmail.com',
  'hotmail.com',
  'yahoo.com',
  'outlook.com',
  'aol.com',
  'icloud.com',
  'live.com',
  'msn.com',
  'me.com',
  'mac.com',
  'googlemail.com',
];

export const PRICES_FOR_PAYMENT_ID = {
  88888: {
    letter: 88888,
    templateId: 88888,
    stripe: DEBUG
      ? process.env.REACT_APP_STRIPE_1_MONTH_DEV
      : process.env.REACT_APP_STRIPE_1_MONTH,
    paypal: DEBUG
      ? process.env.REACT_APP_PAYPAL_1_MONTH_DEV
      : process.env.REACT_APP_PAYPAL_1_MONTH,
    price: '29.99',
    prevPrice: '39.99',
    per: 'month',
    trial: '7',
    trialPrice: '0.99',
    payWallText: 'paywallPage.subscription_info',

    applePay: (t, startingOn) => ({
      total: {
        label: t('applePay.total_label'),
        amount: 99,
      },
      displayItems: [
        {
          label: t('applePay.displayItems.0'), // 'Monthly subscription with 7 days just $0.99 trial period then $29.99/month',
          amount: 99,
        },
        {
          label: t(`applePay.displayItems.1`, { startingOn }), // 'Monthly subscription with 7 days just $0.99 trial period then $29.99/month',
          amount: 2999,
        },
      ],
    }),
  },
  99999: {
    letter: 99999,
    templateId: 99999,
    stripe: process.env.REACT_APP_STRIPE_6_MONTHS,
    paypal: process.env.REACT_APP_PAYPAL_6_MONTHS,
    price: '29.99',
    prevPrice: '39.99',
    per: '6 months',
    trial: '0-day',
    trialPrice: 0,
    payWallText:
      "By pressing start my plan you agree that if you don't cancel at least 24 hours prior to the end of the 7 day introductory offer, you will automatically be charged the full price of the subscription at $29.99 per 1 month until you cancel subscription in settings. Learn more about cancellation and refund policy in our Terms of Use.",
    applePay: (startingOn) => ({
      total: {
        label: '6 Months subscription',
        amount: 2999,
      },
      displayItems: [
        {
          label: `6 Month Plan: Starting on ${startingOn}`,
          amount: 2999,
        },
      ],
    }),
  },
  1: {
    letter: 1,
    templateId: 3,
    stripe: process.env.REACT_APP_STRIPE_LETTER_01,
    paypal: process.env.REACT_APP_PAYPAL_LETTER_01,
    price: '29.99',
    prevPrice: '39.99',
    per: 'month',
    trial: '7-day',
    trialPrice: 0,
    payWallText:
      "By pressing start my plan you agree that if you don't cancel at least 24 hours prior to the end of the 7 day introductory offer, you will automatically be charged the full price of the subscription at $29.99 per 1 month until you cancel subscription in settings. Learn more about cancellation and refund policy in our Terms of Use.",
    applePay: (startingOn) => ({
      total: {
        label: `1 Month subscription with ${PRICES_FOR_PAYMENT_ID['1']?.trial} trial period`,
        amount: 0,
        // type: 'pending'
      },
      displayItems: [
        {
          label: 'Special Offer: 7 days trial period',
          amount: 0,
          // type: 'final'
        },
        {
          label: `1 Month Plan: Starting on ${startingOn}`,
          amount: 2999,
          // type: 'final'
        },
      ],
    }),
  },
  2: {
    letter: 2,
    templateId: 4,
    stripe: process.env.REACT_APP_STRIPE_LETTER_02,
    paypal: process.env.REACT_APP_PAYPAL_LETTER_02,
    price: '29.99',
    prevPrice: '39.99',
    per: '3 months',
    trial: '14-day',
    trialPrice: 0,
    payWallText:
      "By pressing start my plan you agree that if you don't cancel at least 24 hours prior to the end of the 14 day introductory offer, you will automatically be charged the full price of the subscription at $29.99 per 3 months until you cancel subscription in settings. Learn more about cancellation and refund policy in our Terms of Use.",
    applePay: (startingOn) => ({
      total: {
        label: `3 Months subscription with ${PRICES_FOR_PAYMENT_ID['2']?.trial} trial period`,
        amount: 0,
      },
      displayItems: [
        {
          label: 'Special Offer: 14 days trial period',
          amount: 0,
        },
        {
          label: `3 Month Plan: Starting on ${startingOn}`,
          amount: 2999,
        },
      ],
    }),
  },
  3: {
    letter: 3,
    templateId: 5,
    stripe: process.env.REACT_APP_STRIPE_LETTER_02,
    paypal: process.env.REACT_APP_PAYPAL_LETTER_02,
    price: '29.99',
    prevPrice: '39.99',
    per: '3 months',
    trial: '14-day',
    trialPrice: 0,
    payWallText:
      "By pressing start my plan you agree that if you don't cancel at least 24 hours prior to the end of the 14 day introductory offer, you will automatically be charged the full price of the subscription at $29.99 per 3 months until you cancel subscription in settings. Learn more about cancellation and refund policy in our Terms of Use.",
    applePay: (startingOn) => ({
      total: {
        label: `3 Months subscription with ${PRICES_FOR_PAYMENT_ID['3']?.trial} trial period`,
        amount: 0,
      },
      displayItems: [
        {
          label: 'Special Offer: 14 days trial period',
          amount: 0,
        },
        {
          label: `3 Month Plan: Starting on ${startingOn}`,
          amount: 2999,
        },
      ],
    }),
  },
  4: {
    letter: 4,
    templateId: 6,
    stripe: process.env.REACT_APP_STRIPE_LETTER_01,
    paypal: process.env.REACT_APP_PAYPAL_LETTER_01,
    price: '29.99',
    prevPrice: '39.99',
    per: 'month',
    trial: '7-day',
    trialPrice: 0,
    payWallText:
      "By pressing start my plan you agree that if you don't cancel at least 24 hours prior to the end of the 7 day introductory offer, you will automatically be charged the full price of the subscription at $29.99 per 1 month until you cancel subscription in settings. Learn more about cancellation and refund policy in our Terms of Use.",
    applePay: (startingOn) => ({
      total: {
        label: `1 Months subscription with ${PRICES_FOR_PAYMENT_ID['4']?.trial} trial period`,
        amount: 0,
      },
      displayItems: [
        {
          label: 'Special Offer: 7 days trial period',
          amount: 0,
        },
        {
          label: `1 Month Plan: Starting on ${startingOn}`,
          amount: 2999,
        },
      ],
    }),
  },
  5: {
    letter: 5,
    templateId: 7,
    stripe: process.env.REACT_APP_STRIPE_LETTER_02,
    paypal: process.env.REACT_APP_PAYPAL_LETTER_02,
    price: '29.99',
    prevPrice: '39.99',
    per: '3 months',
    trial: '14-day',
    trialPrice: 0,
    payWallText:
      "By pressing start my plan you agree that if you don't cancel at least 24 hours prior to the end of the 14 day introductory offer, you will automatically be charged the full price of the subscription at $29.99 per 3 months until you cancel subscription in settings. Learn more about cancellation and refund policy in our Terms of Use.",
    applePay: (startingOn) => ({
      total: {
        label: `3 Months subscription with ${PRICES_FOR_PAYMENT_ID['5']?.trial} trial period`,
        amount: 0,
      },
      displayItems: [
        {
          label: 'Special Offer: 14 days trial period',
          amount: 0,
        },
        {
          label: `3 Month Plan: Starting on ${startingOn}`,
          amount: 2999,
        },
      ],
    }),
  },
  6: {
    letter: 6,
    templateId: 8,
    stripe: process.env.REACT_APP_STRIPE_LETTER_02,
    paypal: process.env.REACT_APP_PAYPAL_LETTER_02,
    price: '29.99',
    prevPrice: '39.99',
    per: '3 months',
    trial: '14-day',
    trialPrice: 0,
    payWallText:
      "By pressing start my plan you agree that if you don't cancel at least 24 hours prior to the end of the 14 day introductory offer, you will automatically be charged the full price of the subscription at $29.99 per 3 months until you cancel subscription in settings. Learn more about cancellation and refund policy in our Terms of Use.",
    applePay: (startingOn) => ({
      total: {
        label: `3 Months subscription with ${PRICES_FOR_PAYMENT_ID['6']?.trial} trial period`,
        amount: 0,
      },
      displayItems: [
        {
          label: 'Special Offer: 7 days trial period',
          amount: 0,
        },
        {
          label: `3 Month Plan: Starting on ${startingOn}`,
          amount: 2999,
        },
      ],
    }),
  },
  7: {
    letter: 7,
    templateId: 9,
    stripe: process.env.REACT_APP_STRIPE_LETTER_07,
    paypal: process.env.REACT_APP_PAYPAL_LETTER_07,
    price: '89.99',
    prevPrice: '129.99',
    per: '1 year',
    trial: '14-day',
    trialPrice: 0,
    payWallText:
      "By pressing start my plan you agree that if you don't cancel at least 24 hours prior to the end of the 14 day introductory offer, you will automatically be charged the full price of the subscription at $89.99 per 1 year until you cancel subscription in settings. Learn more about cancellation and refund policy in our Terms of Use.",
    applePay: (startingOn) => ({
      total: {
        label: `1 Year subscription with ${PRICES_FOR_PAYMENT_ID['7']?.trial} trial period`,
        amount: 0,
      },
      displayItems: [
        {
          label: 'Special Offer: 14 days trial period',
          amount: 0,
        },
        {
          label: `1 Year Plan: Starting on ${startingOn}`,
          amount: 8999,
        },
      ],
    }),
  },
  8: {
    letter: 8,
    templateId: 10,
    stripe: process.env.REACT_APP_STRIPE_LETTER_07,
    paypal: process.env.REACT_APP_PAYPAL_LETTER_07,
    price: '89.99',
    prevPrice: '129.99',
    per: '1 year',
    trial: '14-day',
    trialPrice: 0,
    payWallText:
      "By pressing start my plan you agree that if you don't cancel at least 24 hours prior to the end of the 14 day introductory offer, you will automatically be charged the full price of the subscription at $89.99 per 1 year until you cancel subscription in settings. Learn more about cancellation and refund policy in our Terms of Use.",
    applePay: (startingOn) => ({
      total: {
        label: `1 Year subscription with ${PRICES_FOR_PAYMENT_ID['8']?.trial} trial period`,
        amount: 0,
      },
      displayItems: [
        {
          label: 'Special Offer: 14 days trial period',
          amount: 0,
        },
        {
          label: `1 Year Plan: Starting on ${startingOn}`,
          amount: 8999,
        },
      ],
    }),
  },
  9: {
    letter: 9,
    templateId: 11,
    stripe: process.env.REACT_APP_STRIPE_LETTER_09,
    paypal: process.env.REACT_APP_PAYPAL_LETTER_09,
    price: '89.99',
    prevPrice: '129.99',
    per: '1 year',
    trial: '1-month',
    trialPrice: 0,
    payWallText:
      "By pressing start my plan you agree that if you don't cancel at least 24 hours prior to the end of the 1 month free introductory offer, you will automatically be charged the full price of the subscription at $89.99 per 1 year until you cancel subscription in settings. Learn more about cancellation and refund policy in our Terms of Use.",
    applePay: (startingOn) => ({
      total: {
        label: `1 Year subscription with ${PRICES_FOR_PAYMENT_ID['9']?.trial} trial period`,
        amount: 0,
      },
      displayItems: [
        {
          label: 'Special Offer: 1 month trial period',
          amount: 0,
        },
        {
          label: `1 Year Plan: Starting on ${startingOn}`,
          amount: 8999,
        },
      ],
    }),
  },
  11: {
    letter: 10,
    templateId: 13,
    stripe: process.env.REACT_APP_STRIPE_LETTER_11,
    paypal: process.env.REACT_APP_PAYPAL_LETTER_11,
    price: '29.99',
    prevPrice: '39.99',
    per: '1 year',
    trial: '1-month',
    trialPrice: 0,
    payWallText:
      "By pressing start my plan you agree that if you don't cancel at least 24 hours prior to the end of the 1 month free introductory offer, you will automatically be charged the full price of the subscription at $29.99 per 1 year until you cancel subscription in settings. Learn more about cancellation and refund policy in our Terms of Use.",
    applePay: (startingOn) => ({
      total: {
        label: `1 Year subscription with ${PRICES_FOR_PAYMENT_ID['11']?.trial} trial period`,
        amount: 0,
      },
      displayItems: [
        {
          label: 'Special Offer: 1 months trial period',
          amount: 0,
        },
        {
          label: `1 Year Plan: Starting on ${startingOn}`,
          amount: 2999,
        },
      ],
    }),
  },
};

export const SUBMIT_PAYMENT_ERROR_MESSAGES =
  'Please check your card number, expiration date, CVV or try another card.';

export const SUBSCRIPTIONS_NAMES = {
  'P-6HH43248N2891443BMWGCEBY': 'subscriptionsNames.7_day_trial',
  'P-2PM91624CR562592MMXLWHHQ': 'subscriptionsNames.7_day_trial',
  price_1P4JCjIQoDVQPlsm0ntz0LYt: 'subscriptionsNames.7_day_trial',
  price_1PhnjDIQoDVQPlsml08YkvT8: 'subscriptionsNames.7_day_trial',
  price_1OQogwIQoDVQPlsmgTr1B3HO: 'subscriptionsNames.7_day_trial',
};

import { useLocation } from 'react-router-dom';
import { format } from 'date-fns';
import { DEBUG } from './constants'

const snakeCaseKeys = require('snakecase-keys');

// eslint-disable-next-line
Date.prototype.isValid = function () {
  // An invalid date object returns NaN for getTime() and NaN is the only
  // object not strictly equal to itself.
  // eslint-disable-next-line
  return this.getTime() === this.getTime();
};

function serializeQuery(params, prefix) {
  const query = Object.keys(params).map((key) => {
    const value = params[key];

    if (params.constructor === Array)
      key = `${prefix}[]`;
    else if (params.constructor === Object)
      key = (prefix ? `${prefix}[${key}]` : key);

    if (typeof value === 'object')
      return serializeQuery(value, key);
    else
      return `${key}=${encodeURIComponent(value)}`;
  });

  return [].concat.apply([], query).join('&');
}

function clean(obj) {
  for (let propName in obj) {

    const val = obj[propName];

    if (val === null || val === undefined || val === '' || (val instanceof Date && val.isValid() === false)) {
      delete obj[propName];
    } else if (val instanceof Date) {
      obj[propName] = format(val, 'yyyy-MM-dd')
    } else if (val instanceof Array) {
      obj[propName] = val.filter(item => (item !== '' && item !== null && item !== undefined));
      if (obj[propName]?.length === 0) {
        delete obj[propName];
      }
    }
  }

  return obj;
}

export default function buildQueryString(params = {}) {
  params = clean(params) || {};
  params = snakeCaseKeys(params);
  params = serializeQuery(params);

  return JSON.stringify(params) === JSON.stringify({}) ? '' : `?${params}`;
}

export const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};
//
// export const MailIcon = (props) =>
//   <SvgIcon width="21" height="14" viewBox="0 0 21 14" fill="transparent">
//     <g opacity="0.2">
//       <rect x="1.5" y="0.5" width="18" height="13" rx="1.5" fill="transparent" stroke="black" />
//       <path
//         d="M9.52381 6.49527C10.0855 6.01378 10.9145 6.01378 11.4762 6.49527L18.6221 12.6204C18.9746 12.9225 18.7609 13.5 18.2967 13.5H2.70326C2.23905 13.5 2.02541 12.9225 2.37786 12.6204L9.52381 6.49527Z"
//         fill="transparent" stroke="black" />
//       <path
//         d="M11.4762 7.50473C10.9145 7.98622 10.0855 7.98622 9.52381 7.50473L2.37786 1.37963C2.02541 1.07753 2.23905 0.5 2.70326 0.5L18.2967 0.499998C18.7609 0.499998 18.9746 1.07753 18.6221 1.37963L11.4762 7.50473Z"
//         fill="white" stroke="black" />
//     </g>
//   </SvgIcon>
//
// export const Checked = (props) =>
//   <SvgIcon {...props} width="11" height="9" viewBox="0 0 11 9" fill="none">
//     <rect y="4.11328" width="3" height="6.18034" rx="1.5" transform="rotate(-45 0 4.11328)" fill="#6E5BB1" />
//     <rect x="8.61523" width="3" height="9" rx="1.5" transform="rotate(45 8.61523 0)" fill="#6E5BB1" />
//   </SvgIcon>

// export const LoadingIcon = ({ id, props }) =>
//   <CircularProgress id={id} />
//
// export function millisToMinutesAndSeconds(millis) {
//   const minutes = Math.floor(millis / 60000);
//   const seconds = ((millis % 60000) / 1000).toFixed(0);
//   return minutes + ':' + (seconds < 10 ? '0' : '') + seconds;
// }


export const mergeArrayByProp = (a, b, p) => {
  b.forEach((bb) => {
    const ai = a.findIndex(aa => aa[p] === bb[p])
    if (ai !== -1)
      a[ai] = { ...(a[ai]), ...bb }
    else
      a.push(bb)
  });
  return a;
};

export const clickToScroll = (selectorID, deltaHeight = 0) => {

  if (selectorID[0] !== '#')
    selectorID = `#${selectorID}`;

  const overviewElement = document.querySelector(selectorID || '');

  if (overviewElement !== null) {

    if (typeof document.body.scroll !== 'undefined') {
      DEBUG && console.log('clickToScroll 1');
      document.body.scroll({
        top: overviewElement.offsetHeight + deltaHeight,
        behavior: 'smooth'
      });
    } else if (typeof document.scroll !== 'undefined') {
      DEBUG && console.log('clickToScroll 2');
      window.scroll({
        top: overviewElement.offsetHeight + deltaHeight,
        behavior: 'smooth'
      });
    }

    document.querySelector(selectorID).scrollMarginInline = 200;

    document.querySelector(selectorID).scrollIntoView({
      behavior: 'smooth',
    })

  }
};

// export const getNextUrl = (flow, currentUrl) => {
//
//   DEBUG && console.log('getNextUrl', flow, currentUrl, ROUTE_FOR_FLOW?.[flow]?.[currentUrl] || 'notFound')
//
//   return ROUTE_FOR_FLOW?.[flow]?.[currentUrl] || 'notFound'
// }


// export const capitalizeFirstLetter = (s) => {
//   if (typeof s !== 'string') return ''
//   return s.charAt(0).toUpperCase() + s.slice(1)
// }

//
// export function chunkify(a = [], n = 1, balanced = false) {
//
//   if (n < 2)
//     return [a];
//
//   let len = a.length,
//     out = [],
//     i = 0,
//     size;
//
//   if (len % n === 0) {
//     size = Math.floor(len / n);
//     while (i < len) {
//       out.push(a.slice(i, i += size));
//     }
//   }
//
//   else if (balanced) {
//     while (i < len) {
//       size = Math.ceil((len - i) / n--);
//       out.push(a.slice(i, i += size));
//     }
//   }
//
//   else {
//
//     n--;
//     size = Math.floor(len / n);
//     if (len % size === 0)
//       size--;
//     while (i < size * n) {
//       out.push(a.slice(i, i += size));
//     }
//     out.push(a.slice(size * n));
//
//   }
//
//   return out;
// }
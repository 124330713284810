import Button from '@mui/material/Button';
import React from 'react';
import { useTranslation } from 'react-i18next';

const ContinueButton = ({ disabled = false, onClick, text }) => {
  const { t } = useTranslation();
  const buttonText = text ? text : t('buttons.continue');
  return (
    <Button
      fullWidth
      size="large"
      focusRipple={false}
      disableRipple={true}
      disableElevation
      disabled={disabled}
      variant="contained"
      sx={
        {
          // textTransform: 'capitalize',
          // background: '#02082D',
          // '&.MuiButton-outlined': {
          //   '&:hover': {
          //     border: '1px solid #02082d',
          //   }
          // },
          // '&.MuiButton-contained': {
          //   '&:hover': {
          //     backgroundColor: '#02082d'
          //   }
          // }
        }
      }
      onClick={onClick}
    >
      {buttonText}
    </Button>
  );
};

export default ContinueButton;

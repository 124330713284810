import React, { useEffect } from 'react';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { Typography } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { gray, lightGray } from '../theme/theme';
import { Trans, useTranslation } from 'react-i18next';
import TextGradientWrapper from './TextGradientWrapper';

const SelectButton = ({ pageName, label, questions = [] }) => {
  const { t } = useTranslation();

  const methods = useFormContext();

  const [selectedValue, setSelectedValue] = React.useState(null);

  const handleChange = (newSelectedValue) => () => {
    setSelectedValue(newSelectedValue);

    methods.setValue(pageName, newSelectedValue);
  };

  useEffect(() => {
    const value = methods.watch(pageName);
    setSelectedValue(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Typography
        mt={3}
        mb={2}
        color="#2F2F37"
        sx={{
          width: '100%',

          fontFamily: 'Montserrat',
          fontSize: '24px',
          fontWeight: 700,
          lineHeight: '34px',
          letterSpacing: '0em',
          textAlign: 'left',
        }}
      >
        <Trans
          t={t}
          i18nKey={label}
          components={{
            gradient: (
              <TextGradientWrapper gradientColor="linear-gradient(95.49deg, #FF006B 10.61%, #8F00FF 60.31%, #01A4FF 95.66%)" />
            ),
          }}
        />
      </Typography>

      <Stack spacing={1}>
        {questions?.map((item) => (
          <Button
            key={item.value}
            variant="contained"
            disableElevation
            sx={{
              borderRadius: 4,
              height: 56,
              textTransform: 'capitalize',
              lineHeight: '26px',
              letterSpacing: '-0.02em',
              '&.MuiButtonBase-root': {
                ...(item.value === selectedValue
                  ? {
                      background:
                        'linear-gradient(0deg, #00C891, #00C891),linear-gradient(0deg, #02082D, #02082D),linear-gradient(100.14deg, #FF006B -2.84%, #8F00FF 89.49%, #01A4FF 164.88%)',
                      color: '#ffffff',
                      fontWeight: 600,
                    }
                  : {
                      backgroundColor: lightGray,
                      color: gray,
                      fontWeight: 400,
                    }),
              },
            }}
            onClick={handleChange(item.value)}
          >
            {t(item.label)}
          </Button>
        ))}
      </Stack>
    </>
  );
};

export default SelectButton;

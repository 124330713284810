import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './localization/i18n';

import '@fontsource/montserrat'; // Defaults to weight 400
import '@fontsource/montserrat/200.css'; // Specify weight
import '@fontsource/montserrat/500.css'; // Specify weight
import '@fontsource/montserrat/600.css'; // Specify weight
import '@fontsource/montserrat/700.css'; // Specify weight

import './index.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
  <App />
  // </React.StrictMode>
);

import('../package.json').then((res) => console.log('v' + res.version));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

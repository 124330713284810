import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './locales/en.json';
import de from './locales/de.json';
import es from './locales/es.json';
import fr from './locales/fr.json';
import it from './locales/it.json';
import pt from './locales/pt.json';
import ru from './locales/ru.json';

const resources = {
  en: {
    translation: en,
  },
  de: {
    translation: de,
  },
  es: {
    translation: es,
  },
  fr: {
    translation: fr,
  },
  it: {
    translation: it,
  },
  pt: {
    translation: pt,
  },
  ru: {
    translation: ru,
  },
};

const locale = localStorage.getItem('smmhub_locale') || 'en';

i18n.use(initReactI18next).init({
  resources,
  fallbackLng: 'en',
  lng: locale,
  interpolation: {
    escapeValue: false, // react already safes from xss
  },
});

export default i18n;

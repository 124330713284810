import React from 'react';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import NotFound from 'pages/NotFound';
import MainLayout from '../layouts/MainLayout';
import { DEBUG, languageList, PAYMENT_SYSTEM } from '../utils/constants';
import { MenuItem, Select } from '@mui/material';
import { useTranslation } from 'react-i18next';

const DynamicPage = React.lazy(() => import('pages/DynamicPage/DynamicPage'));
const EmailPage = React.lazy(() => import('pages/EmailPage'));
const AnalyzingPage = React.lazy(() => import('pages/AnalyzingPage'));
const PaymentPage = React.lazy(() => import('pages/PaymentPage/PaymentPage'));
const OfferPage = React.lazy(() => import('pages/OfferPage/OfferPage'));
const EmailConfirmedPage = React.lazy(() => import('pages/EmailСonfirmedPage'));
const ManageSubscriptionsPage = React.lazy(() =>
  import('pages/ManageSubscriptionsPage/ManageSubscriptionsPage')
);
const PayWallPage = React.lazy(() => import('pages/PayWallPage/PayWallPage'));
const ThanksPage = React.lazy(() => import('pages/ThanksPage/ThanksPage'));
const WelcomeLetter = React.lazy(() =>
  import('pages/WelcomeLetter/WelcomeLetter')
);
const AllInOneApp = React.lazy(() => import('pages/AllInOneApp'));

const AnimatedRoutes = () => {
  const location = useLocation();
  const { i18n } = useTranslation();

  return (
    <>
      {DEBUG && (
        <Select
          value={i18n.language}
          onChange={(e) => i18n.changeLanguage(e.target.value)}
          size="small"
          sx={{
            position: 'fixed',
            top: 8,
            left: 8,
            zIndex: 9999,
            backgroundColor: 'white',
          }}
        >
          {languageList.map((language) => (
            <MenuItem value={language} key={language}>
              {language}
            </MenuItem>
          ))}
        </Select>
      )}
      <SwitchTransition mode="out-in" className="item">
        <CSSTransition
          key={location.pathname}
          timeout={350}
          classNames="item"
          unmountOnExit
          mountOnEnter
        >
          <Routes location={location} key={location.pathname}>
            <Route path="/" element={<MainLayout />}>
              <Route path="/" element={<Navigate to="Page1" />} />
              <Route path=":pageName" element={<DynamicPage />} />
            </Route>
            <Route path="/all-in-one-app" element={<AllInOneApp />} />
            <Route path="/analyzing" element={<AnalyzingPage />} />
            <Route path="/paywall" element={<PayWallPage />} />

            <Route path="/email" element={<EmailPage />} />
            <Route path="/subscriptions" element={<PaymentPage />} />
            <Route path="/offer" element={<OfferPage />} />
            <Route
              path="/manageSubscriptions"
              element={<ManageSubscriptionsPage />}
            />
            <Route path="/emailConfirmed" element={<EmailConfirmedPage />} />
            <Route path="/thanks" element={<ThanksPage />} />
            <Route path="/welcome" element={<WelcomeLetter />} />
            <Route path="/al:path" element={<></>} />

            <Route
              path="/stripe/cancel"
              element={
                <Navigate to={`/subscriptions?type=${PAYMENT_SYSTEM.STRIPE}`} />
              }
            />
            <Route
              path="/stripe/success"
              element={
                <Navigate to={`/thanks?type=${PAYMENT_SYSTEM.STRIPE}`} />
              }
            />

            <Route path="/not-found" element={<NotFound />} />
          </Routes>
        </CSSTransition>
      </SwitchTransition>
    </>
  );
};

export default AnimatedRoutes;

import amplitude from 'amplitude-js';
// import { DEBUG, AMPLITUDE_API_KEY } from '../constants/Constants'
import pack from './../../package.json';
import { AMPLITUDE_API_KEY, DEBUG } from '../utils/constants';
import { gaTracker } from './gaTracker';

let Symbol = require('es6-symbol');
let services = Symbol();
let servicesEnforcer = Symbol();

// export const sendToAnalytics = (metric) => {
//     const body = JSON.stringify({[metric.name]: metric.value});
//     // Use `navigator.sendBeacon()` if available, falling back to `fetch()`.
//     (navigator.sendBeacon && navigator.sendBeacon('/analytics', body)) ||
//         fetch('/analytics', {body, method: 'POST', keepalive: true});
// };

class Tracker {
  static get instance() {
    if (!this[services]) {
      this[services] = new Tracker(servicesEnforcer);
    }
    return this[services];
  }

  static set instance(_) {
    throw new Error("Can't change constant property!");
  }

  constructor() {
    // const service = this;
    this['amplitude'] = (event, data = {}) => {
      // eslint-disable-next-line no-undef
      console.log(event, data);

      if (event instanceof Array) {
        [event, data] = [...event];
      }

      data = { ...{ userProps: {} }, ...data };

      let { userProps, screen, user_id, ...otherData } = data;

      if (screen) localStorage.setItem('screen_id', screen);
      else screen = localStorage.getItem('screen_id');

      const amplitudeData = {
        ...otherData,
        ...{ debug: DEBUG, screen, microtime: window.performance.now() },
      };

      // запретить трекать одинаковые события
      // if ((service?.['prevEvent'] || '') === event && JSON.stringify(service?.['prevEventData']||{}) === JSON.stringify(amplitudeData) ) return;

      // service['prevEvent'] = event;
      // service['prevEventData'] = JSON.stringify(amplitudeData);

      if (DEBUG) {
        console.log('amplitude', event, amplitudeData);
      }

      gaTracker(event, amplitudeData);

      // if (GA_EVENTS[event]) return;

      if (AMPLITUDE_API_KEY) {
        amplitude.getInstance().init(AMPLITUDE_API_KEY);
        amplitude.getInstance().setVersionName(pack.version);
        if (user_id) amplitude.getInstance().setUserId(user_id);

        const identify = new amplitude.Identify();

        Object.keys(userProps || {}).map((name) =>
          identify.setOnce(name, userProps[name])
        );

        amplitude.getInstance().identify(identify);

        amplitude.getInstance().logEvent(event, amplitudeData);
      }
    };
  }

  log(...data) {
    if (DEBUG) {
      console.log(...data);
    }
  }
}

const trackAmplitude = (...data) => Tracker.instance.amplitude(data);

export default trackAmplitude;

export const handleTrack = (name) => trackAmplitude(name);

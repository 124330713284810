import { useEffect, useState } from 'react'
import { postRegisterCookie } from '../api/api';
import { format, addYears } from 'date-fns';

const normalizeCookie = cookie =>
  cookie?.split(';')
    ?.reduce((acc, variant) => {
        acc.push(variant.trim());
        return acc
      }, []
    )
    ?.sort()
    ?.join('; ');

const useCookie = ({ data }) => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);

  const [cookie, setCookie] = useState(null);

  useEffect(() => {
    const session = urlParams.get('session');
    if (session) {
      const endDate = format(addYears(new Date(), 1), 'iii, dd LLL yyyy 00:00:00');
      document.cookie = `session=${session}; expires=${endDate} UTC; path=/`;
    }

    const nCookie = normalizeCookie(document?.cookie);

    if (!!data?.user_id && !!document?.cookie && cookie !== nCookie) {
      setCookie(nCookie);

      postRegisterCookie({ cookie: nCookie })
        .then(r => r)
        .catch(e => setCookie(Date.now()))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, cookie]);
};

export default useCookie